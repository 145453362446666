
.banner-Img {
    max-width: 100%;
    height: 200px;
    width: inherit;
}
.media {
    position: relative;
    display: inline-block;
    width: 100%;
}

.caption {
    position: absolute;
    top: 70px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-weight: bold;
}

.dashboard-background {
    --scale: 1;
    margin-top: 70px;
    background-image: linear-gradient(to bottom, rgba(250, 250, 251,0) 40%,rgba(250, 250, 251,.5) 70%,rgba(250, 250, 251,.9) 90%, rgba(250, 250, 251,1) 100%), url(../../assets/images/platformbannerbackground.jpg);        
    background-position: top;
    background-size: 100% 200px;
    background-repeat: no-repeat;
    height: 200px;
}