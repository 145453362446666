.dashboard-decisions-card{
  //  border-radius: 2rem;
  border-color: #dfd3d4;
  stroke-width: .5px;
}


.dashboard-decisions-div {
  justify-content: center;
  height: 180px;
  overflow: auto;
  position: relative;
}

.dashboard-decisions-empty{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
}

.decisions-div{
  margin-top: 25px;
  margin-bottom: 25px;
  padding-right: 40px;
  margin-left: 30px;
}

.btn-view-all-decisions {
  background-color: #f2f2f2;
  font-weight: 600;
  float: right;
  border-radius: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 90px;
}

.decisions-div{
  // display: flex;
  text-overflow: ellipsis;
  // width: 200px;
}

.decisions-main-header {
  font-family: 'Poppins';
  font-weight: 500;
}

.decisions-sub-header {
  font-family: 'Poppins';
  font-weight: 300;
}

.regionDecisionsHeader {
  background-color: #f7f7f7;
  padding: 10px;
  border-bottom: 1px solid #d1d3d4;
  stroke-width: .5px;
}