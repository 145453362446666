.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.line-separator {
  border-color: #282c34;
  stroke-width: 0.5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 20vh;
  width: 100vw;
  top: 35%;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clipped {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-content {
  margin-top: 100px;
}

.MuiDataGrid-root {
  border: 1px solid rgba(224, 224, 224, 1);
}

.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.MuiDataGrid-columnSeparator {
  color: rgba(224, 224, 224, 1) !important;
}

.MuiDataGrid-cell {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.MuiTablePagination-displayedRows {
  margin-top: 15px;
}

.css-1vjb4cj {
  border: 0px !important;
}

.app-pinnacle-text {
  font-family: 'Poppins';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

// TODO: need to create a stylesheet with the list of pinnacle colors so it can be used throughout application
a:hover {
  color: #70c8b6;
}

#walkme-footer {
  display: none;
}
