.dashboard-actionitems-card {
  // border-radius: 2rem;
  margin-bottom: 10px;
  border-color: #dfd3d4;
  stroke-width: 0.5px;
}

.dashboard-actionitems-div {
  justify-content: center;
  height: 180px;
  overflow: auto;
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  overflow-x: hidden;
}

.actionitems-div {
  font-family: 'Poppins';
  font-size: 12px;
  width: 100%;
  position: absolute;
  left: 30px;
  top: 30px;
  padding-bottom: 30px;
}

.action-item {
  display: inline-block;
  width: 100%;
  padding-right: 60px;
}

.btn-view-all-actionitems {
  background-color: #f2f2f2;
  font-weight: 600;
  float: right;
  border-radius: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 90px;
}

.actions-main-header {
  font-family: 'Poppins';
  font-weight: 500;
}

.actions-sub-header {
  font-family: 'Poppins';
  font-weight: 300;
}

.regionActionsHeader {
  background-color: #f7f7f7;
  padding: 10px;
  border-bottom: 1px solid #d1d3d4;
  stroke-width: 0.5px;
}
