.dashboard-wins-card{
  //  border-radius: 2rem;
  border-color: #dfd3d4;
    // height: 235px;
  stroke-width: .5px;
    // margin-top: 10px;
}

.dashboard-wins-div {
  justify-content: center;
  height: 180px;
  overflow: auto;
  position: relative;
}

.dashboard-wins-empty{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
}

.wins-div{
  padding-right: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 30px;
}

.btn-view-all-wins {
  background-color: #f2f2f2;
  font-weight: 600;
  float: right;
  border-radius: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 90px;
}

.wins-div{
  // display: flex;
  text-overflow: ellipsis;
  // width: 200px;
}

.wins-main-header {
  font-family: 'Poppins';
  font-weight: 500;
}

.wins-sub-header {
  font-family: 'Poppins';
  font-weight: 300;
}

.regionWinsHeader {
  background-color: #f7f7f7;
  padding: 10px;
  border-bottom: 1px solid #d1d3d4;
  stroke-width: .5px;
}