.dashboard-milestones-card{
    // border-radius: 2rem;
    height: 285px;
    margin-top: 10px;
    border-color: #dfd3d4;
    stroke-width: .5px;
  }

  .dashboard-milestones-div {
    height: 450px;
    overflow: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-family: "Poppins";
    overflow-x: hidden;
  }

  .dashboard-milestones-items{
    top: 25px;
    left: 30px;
    position: absolute;
    width: 100%;
  }

  .milestones-main-header {
    font-family: 'Poppins';
    font-weight: 500;
  }

  .milestones-sub-header {
    font-family: 'Poppins';
    font-weight: 300;
  }

  .regionMilestonesHeader {
    background-color: #f7f7f7;
    padding: 10px;
    border-bottom: 1px solid #d1d3d4;
    stroke-width: .5px;
  }