.MuiDataGrid-cell--editing{
    overflow: visible !important;
}
.rbt-menu, .tt-input, .tt-menu { width: 100%; }
.typehead{
    width: 100%;
    // padding: 5px;
}

.rbt-menu {
    margin-top: 45px;
}