.dashboard-documents-card{
    // border-radius: 2rem;
    margin-top: 10px;
    border-color: #dfd3d4;
    stroke-width: .5px;
  }

  .dashboard-documents-div {
    justify-content: center;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .dashboard-documents-items{
    margin-top: 15px;
    margin-left: 30px;
    padding-right: 40px;
    flex-grow: 1;
  }

  .btn-view-all-documents {
    background-color: #f2f2f2;
    font-weight: 600;
    float: right;
    border-radius: 25px;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 40px;
    width: 90px;
  }

  .documents-main-header {
    font-family: 'Poppins';
    font-weight: 500;
  }

  .documents-sub-header {
    font-family: 'Poppins';
    font-weight: 300;
  }

  .regionDocumentsHeader {
    background-color: #f7f7f7;
    padding: 10px;
    border-bottom: 1px solid #d1d3d4;
    stroke-width: .5px;
  }