.dashboard-issues-card {
  // border-radius: 2rem;
  height: 540px;
  border-color: #dfd3d4;
  stroke-width: 0.5px;
}

#issuesTable thead tr th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #ccc;
  color: black;
}

#issuesTable tbody tr td,
#issuesTable thead {
  border: 1px solid #ddd;
  padding: 5px;
}

#issuesTableDiv {
  height: 225px;
  overflow: auto;
}

#issuesTable {
  width: 100%;
  font-size: small;
}

#issuesTable tr:hover {
  background-color: #f2f2f2;
}

.dashboard-issues-div {
  display: flex;
  justify-content: center;
  height: 540px;
  position:relative;
  align-items: center;
}

.dashboard-issues-table {
  height: 225px;
  overflow: auto;
}

.btn-view-all-issues {
  background-color: #f2f2f2;
  font-weight: 600;
  float: right;
  border-radius: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 90px;
}

.issues-main-header {
  font-family: 'Poppins';
  font-weight: 500;
}

.issues-sub-header {
  font-family: 'Poppins';
  font-weight: 300;
}

.regionIssuesHeader {
  background-color: #f7f7f7;
  padding: 10px;
  border-bottom: 1px solid #d1d3d4;
  stroke-width: 0.5px;
}

.indicator {
  border-radius: 4px;
}

.Low_Impact {
  font-weight: 900;
  border-left: 8px solid #6fbf44;
  margin-left: 30px;
}
.Medium_Impact {
  font-weight: 900;
  border-left: 8px solid #fff333;
  margin-left: 30px;
}
.High_Impact {
  font-weight: 900;
  border-left: 8px solid #ed1e35;
  margin-left: 30px;
}

.dashboard-issuesrecords-div {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin-top: 20px;
  // margin-bottom: 25px;
  height: 280px;
  // overflow: auto;
  // width: 100%;
}
