.dashboard-risks-card{
    // border-radius: 2rem;
    margin-top: 10px;
    border-color: #dfd3d4;
    stroke-width: .5px;
    height: 308px;
  }

  .dashboard-risks-div {
    height: 220px;
    position: relative;
  }
  .dashboard-risks-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
  }

  .dashboard-risks-items{
    margin-top: 20px;
  }

  .indicator{
    border-radius: 4px;
  }

  .Low_Low {
    font-weight: 900;
    border-left: 8px solid #6B9040;
    margin-left: 30px;
  }
  .Low_Medium {
    font-weight: 900;
    border-left: 8px solid #8EC051;
    margin-left: 30px;
  }
  .Low_High {
    font-weight: 900;
    border-left: 8px solid #F5BC43;
    margin-left: 30px;
  }
  .Medium_Low {
    font-weight: 900;
    border-left: 8px solid #8EC051;
    margin-left: 30px;
  }
  .Medium_Medium {
    font-weight: 900;
    border-left: 8px solid #F5BC43;
    margin-left: 30px;
  }
  .Medium_High {
    font-weight: 900;
    border-left: 8px solid #EB563E;
    margin-left: 30px;
  }
  .High_Low{
    font-weight: 900;
    border-left: 8px solid #F5BC43;
    margin-left: 30px;
  }
  .High_Medium{
    font-weight: 900;
    border-left: 8px solid #EB563E;
    margin-left: 30px;
  }
  .High_High{
    font-weight: 900;
    border-left: 8px solid #DC4554;
    margin-left: 30px;
  }
  
  .btn-view-all-risks {
    background-color: #f2f2f2;
    font-weight: 600;
    float: right;
    border-radius: 25px;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 40px;
    width: 90px;
  }

  .risks-main-header {
    font-family: 'Poppins';
    font-weight: 500;
  }

  .risks-sub-header {
    font-family: 'Poppins';
    font-weight: 300;
  }
  
  .regionRisksHeader {
    background-color: #f7f7f7;
    padding: 10px;
    border-bottom: 1px solid #d1d3d4;
    stroke-width: .5px;
  }