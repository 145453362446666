
@import '../../app.scss';

.overlay-box {
    background-color: #f5f5f5;
    height: 98%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .overlay-box:hover .desc,
  .overlay-box:focus .desc {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  
  /* opacity 0.01 for accessibility */
  /* adjust the styles like height,padding to match your design*/
  .overlay-box .desc {
    opacity: 0.01;
    font-size: 1rem;
    height: 100%;
    // top: 0;
    padding: 19px 24px 24px;
    transition: all 0.3s ease;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
  
  .ellipsesInfo {
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
    font-size: smaller;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  
  .openButton:hover {
    color: #fff !important;
    background-color:#2aa1af !important;
    border-color: #2aa1af !important;
  }
  // .wrapper {
  //   resize: none;
  //   max-width: 100%;
  //   height: 26vh;
  // }
  .category-container {
    min-height: 70vh;
    position: relative;
    .category-item {
      margin-top: 0;
      
      .categoryCard {
        margin-bottom: 1.5rem;
      }
      .categoryHeading {
        @extend .app-pinnacle-text;
        margin-bottom: 0.5rem;
      }
    }   
  }